export const KLARNA = 'klarna_kp';
export const PAYPAL_EXPRESS = 'paypal_express';
export const PAYPAL_EXPRESS_CREDIT = 'paypal_express_bml';
export const ADYEN_HPP = 'adyen_hpp';
export const TRUSTLY = 'trustly';
export const DIRECT_E_BANKING = 'directEbanking';
export const PAY_WITH_GOOGLE = 'paywithgoogle';
export const GOOGLEPAY = 'googlepay';
export const APPLE_PAY = 'applepay';
export const MOBILEPAY = 'mobilepay';
export const GIROPAY = 'giropay';
export const VIPPS = 'vipps';
export const EBANKING_FI = 'ebanking_FI';
export const MULTIBANCO = 'multibanco';
export const AFTERPAYTOUCH = 'afterpaytouch';
export const IDEAL = 'ideal';
export const PAYMENT_WITH_BUTTON = [
    TRUSTLY,
    PAYPAL_EXPRESS,
    PAY_WITH_GOOGLE,
    APPLE_PAY,
    DIRECT_E_BANKING,
    MOBILEPAY,
    GIROPAY,
    VIPPS,
    EBANKING_FI,
    MULTIBANCO,
    AFTERPAYTOUCH,
    IDEAL,
    GOOGLEPAY
];

export const BCMC = 'bcmc';
export const DOTPAY = 'dotpay';
export const EPS = 'eps';
export const ADYEN_CC = 'adyen_cc';
export const BLIK = 'blik';
export const PAYMENT_WITH_FORM = [ADYEN_CC, BCMC, EPS, DOTPAY, BLIK];

export const CHECK_MONEY = 'checkmo';
export const BANK_TRANSFER = 'banktransfer';
export const PURCHASE_ORDER = 'purchaseorder';
export const EXCLUDED_PAYNMENT_METHODS = [CHECK_MONEY, BANK_TRANSFER, PURCHASE_ORDER];
