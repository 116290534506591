/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { STOCK_TYPE } from 'Component/Product/Stock.config';
import {
    BIG_PLACEHOLDER_CONFIG
} from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.config';
import { toggleOptions } from 'Store/Product/Product.action';
import { MixType } from 'Type/Common.type';
import { AttributesType, ItemsType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import { checkIfVariantExists, getBooleanLabel } from 'Util/Product';
import { getQueryParam } from 'Util/Url';

import ProductConfigurableAttributes from './ProductConfigurableAttributes.component';
/** @namespace Bodypwa/Component/ProductConfigurableAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    showOptions: state.ProductReducer?.showOptions || {}
});

/** @namespace Bodypwa/Component/ProductConfigurableAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOptions: (showOptions) => dispatch(toggleOptions(showOptions))
});
/** @namespace Bodypwa/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends PureComponent {
    static propTypes = {
        getLink: PropTypes.func,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        isExpandable: PropTypes.bool,
        showProductAttributeAsLink: PropTypes.bool,
        variants: ItemsType,
        mix: MixType,
        isReady: PropTypes.bool,
        numberOfPlaceholders: PropTypes.arrayOf(PropTypes.number),
        configurable_options: AttributesType.isRequired,
        inStock: PropTypes.bool,
        productUrl: PropTypes.string.isRequired,
        isSwiper: PropTypes.bool,
        isMobile: PropTypes.bool,
        variantsPage: PropTypes.bool,
        showOptions: PropTypes.shape({}),
        product: PropTypes.shape({
            sku: PropTypes.string,
            variants: PropTypes.arrayOf(PropTypes.shape({
                attributes: PropTypes.shape({
                    attribute_value: PropTypes.string
                })
            }))
        }).isRequired,
        sku: PropTypes.string.isRequired,
        toggleOptions: PropTypes.func.isRequired,
        categoryId: PropTypes.number.isRequired,
        requestProductListVariants: PropTypes.func.isRequired,
        linkType: PropTypes.string
    };

    static defaultProps = {
        getLink: noopFn,
        isExpandable: true,
        showProductAttributeAsLink: true,
        variants: null,
        isReady: true,
        mix: {},
        numberOfPlaceholders: BIG_PLACEHOLDER_CONFIG,
        inStock: true,
        isSwiper: false,
        isMobile: false,
        variantsPage: false,
        showOptions: {},
        linkType: ''
    };

    containerFunctions = {
        handleOptionClick: this.handleOptionClick.bind(this),
        getSubHeading: this.getSubHeading.bind(this),
        isSelected: this.isSelected.bind(this),
        getLink: this.getLink.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        getStockLabel: this.getStockLabel.bind(this)
    };

    state = {
        selectedOption: {},
        optionsToSelect: {},
        isShowOptions: true
    };

    componentDidMount() {
        const { toggleOptions, product: { sku } = {}, mix: { block } } = this.props;
        this.isPlp = block === 'ProductCard';
        const {
            isShowOptions
        } = this.state;

        if (!this.isPlp) {
            toggleOptions({ [ sku ]: isShowOptions });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            product: { variants, sku: pSku } = {}, sku, updateConfigurableVariant, toggleOptions, showOptions
        } = this.props;
        const {
            optionsToSelect: { attribute_code, attribute_value }, isShowOptions
        } = this.state;

        if (!this.isPlp && pSku && typeof showOptions[pSku] === 'undefined') {
            toggleOptions({ [ pSku ]: isShowOptions });
        }

        const { isShowOptions: prevIsShowOptions } = prevState;
        if (isShowOptions !== prevIsShowOptions && !this.isPlp) {
            toggleOptions({ [ pSku ]: isShowOptions });
        }
        if ((!sku && attribute_code)
         || (checkIfVariantExists(variants || [], { attribute_code, attribute_value }) && attribute_code)) {
            updateConfigurableVariant(attribute_code, attribute_value, true);
            this.setState({ optionsToSelect: {} });
        }
    }

    componentWillUnmount() {
        const { toggleOptions, product: { sku } = {} } = this.props;
        if (!this.isPlp) {
            toggleOptions({ [ sku ]: true });
        }
    }

    containerProps() {
        const {
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            variants,
            productUrl,
            isSwiper,
            isMobile,
            variantsPage,
            showOptions,
            product
        } = this.props;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return {
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            variants,
            productUrl,
            isSwiper,
            isMobile,
            variantsPage,
            showOptions,
            isSkuParam: !!urlParams.get('sku'),
            product
        };
    }

    getStockLabel(attribute_code, attribute_options, parameters) {
        const { showOptions, product: { sku } } = this.props;
        return showOptions[sku]
            ? attribute_options[parameters[attribute_code]].label
            : __('%s is out of stock', attribute_options[parameters[attribute_code]].label);
    }

    getLink({ attribute_code, attribute_value }) {
        const { getLink } = this.props;

        return getLink(attribute_code, attribute_value);
    }

    _parseSelectedFilters(filtersString) {
        return this._formatSelectedFiltersString(filtersString.split(';'));
    }

    handleOptionClick({ attribute_code, attribute_value }) {
        const {
            linkType, sku, categoryId, requestProductListVariants, product: { variants, page } = {}
        } = this.props;

        if ((!variants || !checkIfVariantExists(variants, { attribute_code, attribute_value }, false))
         && (sku && !linkType)) {
            requestProductListVariants({
                args: {
                    filter: { productsSkuArray: [sku] }
                },
                id: categoryId,
                page,
                isPdp: false
            });
        }

        this.setState({ optionsToSelect: { attribute_code, attribute_value } });
    }

    getSubHeading({
        attribute_values, attribute_code, attribute_options, is_boolean = false
    }) {
        return attribute_values.reduce((acc, attribute_value) => (this.isSelected({
            attribute_code,
            attribute_value
        })
            ? [...acc, getBooleanLabel(attribute_options[attribute_value].label, is_boolean)]
            : acc), []).join(', ');
    }

    _formatSelectedFiltersString(string) {
        return string.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [key]: value.split(',') };
        }, {});
    }

    isSelected({ attribute_code, attribute_value, attribute_values }) {
        const {
            // eslint-disable-next-line react/prop-types
            parameters = {}, updateConfigurableVariant, mix: { block }, product: { variants } = {}
        } = this.props;
        const isPlp = block === 'ProductCard';
        const currentParameter = parameters[attribute_code];
        const selectedFilters = this._parseSelectedFilters(getQueryParam('customFilters', location) || '');
        const isSelectedFilter = selectedFilters[attribute_code]?.includes(attribute_value);
        const { selectedOption } = this.state;
        const isShowOptions = this.getIsConfigurableAttributeAvailable({ attribute_code, attribute_value });
        // When the current parameter is undefined.
        if (currentParameter === undefined) {
            if (isSelectedFilter && selectedOption.attribute_value === undefined && variants.length) {
                updateConfigurableVariant(attribute_code, attribute_value);
                this.setState({ selectedOption: { attribute_code, attribute_value } });
                if (!isPlp) {
                    this.setState({
                        isShowOptions
                    });
                }

                return true;
            }

            return false;
        }

        // When the current parameter is an array.
        if (Array.isArray(currentParameter)) {
            const hasAttribute = currentParameter.includes(attribute_value);
            if (hasAttribute && !isPlp) {
                this.setState({
                    isShowOptions
                });
            }

            return hasAttribute;
        }

        // When there's only one attribute value.
        if (attribute_values.length === 1 && !isPlp) {
            this.setState({
                isShowOptions
            });

            return true;
        }

        const isSelected = currentParameter === attribute_value;
        if (isSelected) {
            if (!isPlp) {
                this.setState({
                    isShowOptions
                });
            }

            // Default case.
            return isSelected;
        }

        return false;
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === STOCK_TYPE.IN_STOCK
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
                );
            });
    }

    render() {
        return (
            <ProductConfigurableAttributes
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurableAttributesContainer);
