import parser from 'html-react-parser';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';
/** @namespace Bodypwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    renderAlternateLinks() {
        const { hreflangs } = this.props;

        if (!hreflangs) {
            return null;
        }

        return hreflangs.map((store) => {
            const {
                url = null,
                code = null
            } = store;

            return (
                <link
                  key={ code }
                  href={ url }
                  hrefLang={ code }
                  rel="alternate"
                />
            );
        }).reverse();
    }

    renderRichSnipet() {
        const {
            seo_markup: {
                social_markup, rich_snippets
            } = {}
        } = this.props;

        return (
            <>
                { Object.keys(rich_snippets || {}).map((item) => parser(rich_snippets[item] || '')) }
                { parser(social_markup || '') }
            </>
        );
    }

    renderCanonical() {
        const { canonical_url } = this.props;

        if (!canonical_url) {
            return null;
        }

        return (
            <link rel="canonical" href={ canonical_url } />
        );
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderAlternateLinks() }
                { this.renderRichSnipet() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property || 'meta' }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }
}

export default MetaComponent;
