/* eslint-disable max-lines */
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';

import CampaignLabel from 'Component/CampaignLabel';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductConfigurableAttributes
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import ProductPrice from 'Component/ProductPrice';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCard as SourceProductCard
} from 'SourceComponent/ProductCard/ProductCard.component';
import media, { PRODUCT_MEDIA } from 'Util/Media';
/** @namespace Bodypwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
        this.state = {
            showAltImage: false
        };
    }

    renderCardLinkWrapper(children) {
        return (
            <div
              block="ProductCard"
              elem="Link"
            >
                { children }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    toggleAltImage(showAlt) {
        this.setState({ showAltImage: showAlt });
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderTextPlaceholder(length = '') {
        return <TextPlaceholder length={ length } />;
    }

    renderPrice() {
        const {
            getActiveProduct,
            inStock,
            product: {
                type_id: baseType,
                price_range: configurablePriceRange
            } = {}
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId
        } = getActiveProduct();

        if (!priceRange || !configurablePriceRange) {
            return this.renderTextPlaceholder('half');
        }

        if (!inStock) {
            // If configurable, do not render price
            if (typeId === PRODUCT_TYPE.configurable) {
                return this.renderConfigurablePrice();
            }

            // If bundle is out of stock, show out of stock msg
            if (typeId === PRODUCT_TYPE.bundle) {
                return (
                    <div
                      block={ this.className }
                      elem="PriceWrapper"
                    >
                        { __('Out of stock') }
                    </div>
                );
            }
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }

    renderConfigurablePrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderPicture() {
        const {
            product: {
                id, name, alt_image
            }, getActiveProductImages, thumbnail, device, isMobile
        } = this.props;
        const { showAltImage } = this.state;
        const { small_image: { url: activeUrl, image_cover } = {}, id: idActive } = getActiveProductImages();

        const { path: altImagePath, image_cover: altImageCover } = alt_image || {};

        const checkAltImage = (showAltImage && altImagePath && !device.isMobile);
        this.sharedComponent = (
            <div
                // eslint-disable-next-line react/jsx-no-bind
              onMouseEnter={ () => this.toggleAltImage(true) }
                // eslint-disable-next-line react/jsx-no-bind
              onMouseLeave={ () => this.toggleAltImage(false) }
            >
                <Image
                  imageRef={ this.imageRef }
                    // eslint-disable-next-line no-nested-ternary
                  src={ checkAltImage ? media(altImagePath, PRODUCT_MEDIA)
                      : id !== idActive && activeUrl ? activeUrl : thumbnail }
                  alt={ name }
                  ratio="custom"
                  mix={ {
                      block: 'ProductCard',
                      elem: 'Picture',
                      mods: { isImageFitCover: checkAltImage ? !!+altImageCover : !!+image_cover }
                  } }
                  isPlaceholder={ !id }
                />
            </div>
        );

        return isMobile ? (
            <div>
                <Image
                  imageRef={ this.imageRef }
                    // eslint-disable-next-line no-nested-ternary
                  src={ checkAltImage ? media(altImagePath, PRODUCT_MEDIA)
                      : id !== idActive && activeUrl ? activeUrl : thumbnail }
                  alt={ name }
                  ratio="custom"
                  mix={ {
                      block: 'ProductCard',
                      elem: 'Picture',
                      mods: { isImageFitCover: checkAltImage ? !!+altImageCover : !!+image_cover }
                  } }
                  isPlaceholder={ !id }
                />
            </div>
        ) : this.sharedComponent;
    }

    renderUspLabel() {
        const {
            product: {
                attributes: {
                    product_label_usp: {
                        attribute_value,
                        attribute_options
                    } = {}
                }
            }
        } = this.props;

        if (!attribute_value) {
            return null;
        }

        const uspLabel = attribute_options[attribute_value].label;

        if (!uspLabel) {
            return null;
        }

        return (
            <div block="ProductCard" elem="UspBadge">
                { uspLabel }
            </div>
        );
    }

    // TODO: Sale label needs to be removed later, class created
    renderLink() {
        const {
            linkTo, product: {
                url
            }, product,
            onProductClick,
            productRef
        } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="FigureReview"
                >
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
            );
        }

        return (
            <Link
              to={ linkTo }
              block="ProductCard"
              elem="FigureReview"
              onClick={ onProductClick }
            >
                <div
                  block="ProductCard"
                  elem="GalleryBadge"
                  ref={ productRef }
                >
                    <CampaignLabel
                      product={ product }
                      isAbsolute={ false }
                    />
                    { this.renderUspLabel() }
                </div>
                <figure block="ProductCard" elem="Figure">
                    { this.renderPicture() }
                </figure>
            </Link>
        );
    }

    renderBrand() {
        const {
            product: {
                attributes: {
                    brand_name: {
                        attribute_value, attribute_options
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value) {
            return false;
        }

        return (
            <div block="ProductCard" elem="Brand">
                { attribute_options[attribute_value].label }
            </div>
        );
    }

    renderCardContent() {
        const { renderContent, linkTo } = this.props;
        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    { this.renderLink() }
                    <div block="ProductCard" elem="Content">
                        { this.renderConfigurableOptions() }
                        { this.renderSimpleOptions() }
                        { this.renderBrand() }
                        <Link
                          to={ linkTo }
                        >
                        { this.renderName(false) }
                        { this.renderPrice() }
                        </Link>
                    </div>
                    <div block="ProductCard" elem="Options">
                        { this.renderBottomData() }
                    </div>
                </>
            ))
        );
    }

    renderSimpleOptions() {
        const {
            product: {
                attributes: {
                    product_card_text_swatch: {
                        attribute_value,
                        attribute_options
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value) {
            return null;
        }

        const textSwatch = attribute_options[attribute_value].label;

        return (
                <p className="ProductCard-SimpleOptions">{ textSwatch }</p>
        );
    }

    renderBottomData() {
        return (
            <div block="ProductCard" elem="Footer">
                { this.renderProductActions() }
            </div>
        );
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product,
            product: { type_id: type, variants = [], sku },
            linkTo: { pathname },
            variantsPage,
            isSwiper,
            device,
            categoryPage,
            categoryId,
            requestProductListVariants,
            linkType
        } = this.props;

        if (!type) {
            return (
                <div
                  block="ProductCard"
                  elem="Name"
                >
                    <TextPlaceholder length="medium" height="content" gap="medium" />
                </div>
            );
        }
        // Custom in stock constant to force swatches to always display on plp
        const inStock = true;
        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  linkType={ linkType }
                  product={ product }
                  categoryId={ categoryId }
                  categoryPage={ categoryPage }
                  requestProductListVariants={ requestProductListVariants }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  sku={ sku }
                  updateConfigurableVariant={ setActiveProduct }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  productUrl={ pathname }
                  isSwiper={ isSwiper }
                  variantsPage={ variantsPage }
                  isMobile={ device.isMobile }
                />
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            layout,
            isSwiper
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              className={ isSwiper ? 'swiper-slide' : '' }
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
